import {LitElement, html, css} from 'lit';
import {playerStyles} from './styles';
import {customElement, property} from 'lit/decorators.js';

import {defineNextImageId} from '../../utils/utils';
import {IImage} from '../../models';

// @ts-ignore
import {displayHotspots} from '../../utils/hotspot.js';
import {convertUrlSelectedImage} from '../../utils/cloudflare';

@customElement('player-gallery')
export class PlayerGallery extends LitElement {
  static styles = [
    playerStyles,
    css`
      :host {
        display: block;
        width: 100%;
        height: 100%;
      }
    `,
  ];

  @property() shootingGallery: IImage[] = [];
  @property() exitFullscreen: any;
  @property() mozCancelFullScreen: any;
  @property() webkitExitFullscreen: any;
  @property() fullscreenElement: any;
  @property() mozFullScreenElement: any;
  @property() webkitFullscreenElement: any;
  @property() urlSelectedImage: string = '';
  @property() hotspotDefaultUrl: string = '';
  @property() hotspotWarningUrl: string = '';
  @property() hotspots: any = [];
  @property() idImage: number = 0;
  @property() imageNumber: number = 0;
  @property() galleryLength: number = 0;
  @property() disableCounter: boolean = false;
  @property() isFullScreen: boolean = false;
  @property() close: any = null;
  @property() closeFull: any = null;

  connectedCallback() {
    super.connectedCallback();
    if (this.shootingGallery.length > 0) {
      this.galleryLength = this.shootingGallery.length;

      const image = this.shootingGallery[this.idImage];

      if (image) {
        this.urlSelectedImage = image.url;
      }
    }
  }

  update(changedProperties: any) {
    super.update(changedProperties);

    if (changedProperties.has('idImage')) {
      this.changeHotspot(this.idImage);
    }

    if (changedProperties.has('hotspots')) {
      this.initHotspot();
    }
  }

  render() {
    return html`
      ${this.isFullScreen
        ? html`
            <div class="imgFullScreen">
              <div @click="${this.closeFullScreen}" class="close"></div>
              <img
                @click="${this.closeFullScreen}"
                alt="vehicle-fullscreen"
                class="no-drag"
                src="${this.urlSelectedImage}"
              />
              <div class="rec">
                <div class="innerRec">
                  <div
                    class="fullscreen_btn previous_btn ripple"
                    @click="${this.handlePreviousImage}"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 477.175 477.175"
                    >
                      <path
                        d="M145.188 238.575l215.5-215.5c5.3-5.3 5.3-13.8 0-19.1s-13.8-5.3-19.1 0l-225.1 225.1c-5.3 5.3-5.3 13.8 0 19.1l225.1 225c2.6 2.6 6.1 4 9.5 4s6.9-1.3 9.5-4c5.3-5.3 5.3-13.8 0-19.1l-215.4-215.5z"
                      />
                    </svg>
                  </div>
                  <div
                    class="fullscreen_btn next_btn ripple"
                    @click="${this.handleNextImage}"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 477.175 477.175"
                    >
                      <path
                        d="M360.731 229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1 0s-5.3 13.8 0 19.1l215.5 215.5-215.5 215.5c-5.3 5.3-5.3 13.8 0 19.1 2.6 2.6 6.1 4 9.5 4 3.4 0 6.9-1.3 9.5-4l225.1-225.1c5.3-5.2 5.3-13.8.1-19z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          `
        : html``}
      <div id="image-gallery-hotspots">
        <div class="hotspotContainer"></div>
        <div class="image-container" @click="${this.displayFullScreen}">
          <img
            alt="vehicle"
            class="imageGallery nod-rag visible"
            src="${convertUrlSelectedImage(this.urlSelectedImage)}"
            onerror='this.onerror = null; this.src="${this.urlSelectedImage}"'
          />
          ${!this.disableCounter
            ? html`<div class="nbPhotos">
                ${this.idImage + 1}/${this.galleryLength}
              </div>`
            : html``}
        </div>
      </div>
    `;
  }

  initHotspot() {
    const container =
      this.shadowRoot && this.shadowRoot.querySelector('.hotspotContainer');
    displayHotspots(container, 0, this.shootingGallery);
  }

  changeHotspot(newId: number) {
    const container =
      this.shadowRoot && this.shadowRoot.querySelector('.hotspotContainer');
    this.urlSelectedImage = this.shootingGallery[newId].url;
    displayHotspots(container, newId, this.shootingGallery);
    window.addEventListener('resize', () =>
      displayHotspots(container, newId, this.shootingGallery)
    );
  }

  touchControl(e: any) {
    if (e.key === 'Escape') {
      this.closeFullScreen();
    }
  }

  handleNextImage() {
    const name = 'next';
    const id = defineNextImageId(this.idImage, name, this.galleryLength);
    this.customDispatchEvent(id);
  }

  handlePreviousImage() {
    const name = 'prev';
    const id = defineNextImageId(this.idImage, name, this.galleryLength);
    this.customDispatchEvent(id);
  }

  customDispatchEvent(id: number) {
    this.dispatchEvent(
      new CustomEvent('child-property-changed', {
        bubbles: true, // propagates beyond self
        composed: true, // propagates through shadow boundaries
        detail: {
          childProperty: id,
        },
      })
    );
  }

  displayFullScreen() {
    this.close = this.touchControl.bind(this);
    this.closeFull = this.closeFullScreen.bind(this);

    this.isFullScreen = true;

    document.addEventListener('keydown', this.close);
    document.addEventListener('fullscreenchange', this.closeFull);
    document.addEventListener('webkitfullscreenchange', this.closeFull);
    document.addEventListener('mozfullscreenchange', this.closeFull);
    document.addEventListener('MSFullscreenChange', this.closeFull);
  }

  closeFullScreen() {
    document.removeEventListener('keydown', this.close);

    if (
      !document.fullscreenElement &&
      // @ts-ignore
      !document.webkitFullscreenElement &&
      // @ts-ignore
      !document.mozFullScreenElement &&
      // @ts-ignore
      !document.msFullscreenElement
    ) {
      const el = document.querySelector('.imgFullScreen');
      el && this.shadowRoot && this.shadowRoot.appendChild(el);
    }

    this.isFullScreen = false;
  }
}

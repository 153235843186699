export function defineNextImageId(
  currentId: number,
  name: string | null,
  arrayLength: number
) {
  let newImageId;

  if (name === 'next' || name === 'ArrowRight') {
    currentId + 1 > arrayLength - 1
      ? (newImageId = 0)
      : (newImageId = currentId + 1);
  } else if (name === 'prev' || name === 'ArrowLeft') {
    currentId - 1 < 0
      ? (newImageId = arrayLength - 1)
      : (newImageId = currentId - 1);
  } else newImageId = -1;

  return newImageId;
}

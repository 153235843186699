import {LitElement, html, css} from 'lit';
import {property} from 'lit/decorators.js';
// @ts-ignore
import {displayHotspots, deleteHotspots} from '../../utils/hotspot.js';
// @ts-ignore
import {Scroll} from '../../utils/scrolling.js';
import styles from './styles';
import {IImage, ImageType, IShootingPhotos} from '../../models';

import '../playerStartScreen/player-startscreen';

import 'hammerjs';

export class Player360Outside extends LitElement {
  static styles = [
    css`
      :host {
        display: block;
        width: 100%;
        height: 100%;
      }
    `,
    styles,
  ];

  @property() urlSelectedImage: string = '';
  @property() shooting: any;
  @property() stamp: string = '';
  @property() disableAutoRotate: boolean = false;
  @property() startScreen: string = 'none';
  @property() infoBackgroundColor: string = '';
  @property() imageIndexOnPan: any = null;
  @property() images: any = [];
  @property() imageLowQuality: any = [];
  @property() imagesHd: any = [];
  @property() hotspots: any = [];
  @property() posX: number = 0;
  @property() loading: boolean = false;
  @property() displayStartScreen: boolean = true;
  @property() visibleImgId: number = 0;
  @property() isScrolling: boolean = false;
  @property() scrolled: any = {};
  @property() resetScrollEvent: boolean = false;
  @property() touched: boolean = false;
  @property() loadedImages: number[] = [];
  @property() loadedImagesLowQuality: any = [];
  @property() loadedImagesLowQualityErrors: boolean[] = [];
  @property() urlSelectedHotspot: string = '';
  @property() loadedHDImages: number[] = [];
  @property() isFullscreen: boolean = false;
  @property() hasHDPhotos: boolean = false;
  @property() hotspotDefaultUrl: string | undefined = undefined;
  @property() hotspotWarningUrl: string | undefined = undefined;

  connectedCallback() {
    super.connectedCallback();
    this.buildImages();
    this.scrolled = new Scroll();
  }

  firstUpdated(changedProperties: any) {
    super.firstUpdated(changedProperties);

    const imageContainer = this.renderRoot.querySelector(
      '.player360Listener'
    ) as HTMLElement;
    const type = this.hasHDPhotos ? 'HD' : 'STANDARD';

    let tryit: any;

    this.initializeHammer(imageContainer, this.images);
    this.fullScreenDetection();
    this.resizeImage();

    this.loading = true;

    this.loadingImages(this.images).then(() => {
      this.autoRotateInitialisation();
      this.preloadImages(this.images, type);

      if (!this.hasHDPhotos) {
        this.loadImagesBackground(this.images);
      }
    });

    window.addEventListener('resize', () => this.resizeImage());

    window.addEventListener('resize', () => {
      if (this.touched) {
        clearTimeout(tryit);
        tryit = setTimeout(() => this.handleDisplayHotspots(), 10);
      }
    });
  }

  updated(changedProperties: any) {
    super.updated(changedProperties);

    if (changedProperties.has('shooting')) {
      this.buildImages();
      this.changeViews();
      this.resetScroll();
    }

    if (changedProperties.has('visibleImgId')) {
      this.changeViews();
    }

    if (changedProperties.has('isScrolling')) {
      if (changedProperties.get('isScrolling') !== undefined) {
        this.eventsInScroll();
      }
    }

    if (changedProperties.has('resetScrollEvent')) {
      this.resetScroll();
    }

    if (changedProperties.has('touched')) {
      if (changedProperties.get('touched') !== undefined) {
        this.isTouched();
      }
    }

    if (changedProperties.has('isFullscreen')) {
      this.resizeImage();
    }
  }

  render() {
    return html`
      <!-- Loaded Images -->
      <div class="loadedImagesContainer"></div>
      <div class="loadedImagesLowQualityContainer"></div>
      <div class="loadedHDImagesContainer"></div>

      <!-- Div Event Listener -->
      <div class="player360Listener"></div>

      <!-- player -->
      <figure class="player360Container">
        <img class="player360Image notSelectable" src="" alt="" />
      </figure>

      <!-- Stamp -->
      <div class="stampytStamp"></div>

      <!-- Bandeau de départ -->
      ${this.displayStartScreen
        ? html`<player-startscreen
            infoBackgroundColor="${this.infoBackgroundColor}"
            startScreen="${this.startScreen}"
          ></player-startscreen>`
        : html``}
    `;
  }

  buildImages() {
    this.images = this.shooting.images.filter(
      (e: IImage) => e.type === ImageType.original
    );

    this.isHDShooting(this.images);

    if (this.images.length === 0) {
      this.images = this.shooting.images.filter(
        (e: IImage) => e.type === ImageType.stamped
      );
    }
  }

  isHDShooting(images: IImage[]) {
    if (images.some((e: IImage) => !!e.urlHD)) {
      this.hasHDPhotos = true;
    }
  }

  resizeImage() {
    const imageContainer = this.renderRoot.querySelector(
      '.player360Container'
    ) as HTMLElement;
    const image = this.renderRoot.querySelector(
      '.player360Image '
    ) as HTMLElement;

    const elW = imageContainer.clientWidth;
    const elH = imageContainer.clientHeight;
    const imgWidth = image.clientWidth;
    const imgHeight = image.clientHeight;

    if (elW < imgWidth) {
      image.style.height = 'auto';
      image.style.width = '100%';
    }

    if (elH < imgHeight) {
      image.style.height = '100%';
      image.style.width = 'auto';
    }
  }

  fullScreenDetection() {
    function detectFullScreen() {
      return !!(
        document.fullscreenElement ||
        // @ts-ignore
        document.webkitFullscreenElement ||
        // @ts-ignore
        document.mozFullScreenElement ||
        // @ts-ignore
        document.msFullscreenElement
      );
    }

    const isFullscreen = detectFullScreen();
    this.isFullscreen = isFullscreen;

    isFullscreen && this.displayImage();

    document.addEventListener('fullscreenchange', () => {
      this.isFullscreen = detectFullScreen();
      this.resetScroll();
      this.displayImage();
      this.initializeScroll();
    });
  }

  // Event track
  event(e: Event, data: IShootingPhotos) {
    this.touched = true;
    if (!this.isScrolling) {
      // nouvelle id d'image
      if (e.type === 'panend') {
        this.imageIndexOnPan = null;
      }
      e.type === 'pan' && this.newImageIdOnMove(e, data);
    } else {
      this.scrolled.moveImage(e);
    }
    e.type === 'pinch' && this.scrolled.pinch(e, this.setScrolled.bind(this));
  }

  initializeHammer(eventContainer: HTMLElement, images: IShootingPhotos) {
    // @ts-ignore
    const hammer = new Hammer(eventContainer);
    // @ts-ignore
    hammer.get('pan').set({direction: Hammer.DIRECTION_ALL});
    hammer.get('pinch').set({enable: true});
    hammer.on('pan pinch panstart panend panleft panright', (e: any) =>
      this.event(e, images)
    );
  }

  // Affiche les hotspots
  handleDisplayHotspots() {
    const imageContainer = this.renderRoot.querySelector('.player360Listener');

    if (this.images.length === 0) return;

    displayHotspots(
      imageContainer,
      this.visibleImgId,
      this.images,
      this.hotspotDefaultUrl,
      this.hotspotWarningUrl
    );
  }

  // Initialise autorotation and scroll if not
  autoRotateInitialisation() {
    if (this.disableAutoRotate) {
      this.initializeScroll();
    } else {
      this.autoRotate();
    }
  }

  // event quand le panorama est touché
  isTouched() {
    if (this.touched) {
      this.displayStartScreen = false;
      this.disableAutoRotate = true;
    }
  }

  // Observer du changement du status du scroll
  eventsInScroll() {
    const imagesStandardContainer = this.renderRoot.querySelector(
      '.loadedImagesContainer'
    ) as HTMLElement;
    const imagesHDContainer = this.renderRoot.querySelector(
      '.loadedHDImagesContainer'
    ) as HTMLElement;
    const standardImagesIsLoaded =
      this.loadedImages.indexOf(this.visibleImgId) !== -1;
    const hdImagesIsLoaded =
      this.loadedHDImages.indexOf(this.visibleImgId) !== -1;

    this.hotspotDisplayControl();

    if (this.hasHDPhotos) {
      this.loadHDPhoto(
        this.images,
        this.visibleImgId,
        'HD',
        hdImagesIsLoaded,
        imagesHDContainer
      );
    } else {
      this.loadHDPhoto(
        this.images,
        this.visibleImgId,
        'STANDARD',
        standardImagesIsLoaded,
        imagesStandardContainer
      );
    }
  }

  // Precharge les images
  preloadImages(images: any, type: string) {
    if (images.length > 0) {
      for (let i = 0; i < images.length; i += 1) {
        const image = new Image();

        if (type === 'STANDARD') {
          image.src = images[i].url;
        } else if (type === 'HD') {
          image.src = images[i].urlHD;
        }
      }
    }
  }

  loadHDPhoto(
    images: IImage[],
    id: number,
    type: string,
    loadedImages: boolean,
    container: HTMLElement
  ) {
    const currentContainer = this.renderRoot.querySelector(
      '.player360Image'
    ) as HTMLImageElement;
    const urlImageLow = images[id].urlLow;
    const urlImage = images[id].url;
    const urlImageHD = images[id].urlHD;
    let url;

    if (!urlImageHD) url = urlImage;
    else url = type === 'HD' ? urlImageHD : urlImage;

    if (this.isScrolling) {
      if (loadedImages) {
        replaceUrl(currentContainer, url);
      } else {
        this.loadImage(container, url).then(() => {
          currentContainer.src = !!urlImageHD ? urlImageHD : urlImage;
        });

        type === 'STANDARD'
          ? (this.loadedImages[this.visibleImgId - 1] = this.visibleImgId)
          : (this.loadedHDImages[this.visibleImgId - 1] = this.visibleImgId);
      }
    } else {
      // On sort du scroll
      this.isFullscreen
        ? replaceUrl(currentContainer, urlImage)
        : replaceUrl(currentContainer, !!urlImageLow ? urlImageLow : urlImage);
    }

    function replaceUrl(currentContainer: any, urlImageHD: string) {
      currentContainer.src = urlImageHD;
    }
  }

  // Ajoute ou retire les hotspots selon la position du scroll
  hotspotDisplayControl() {
    const allHotspotDom = this.renderRoot.querySelectorAll('.hotspot');

    this.touched = true;

    if (!this.isScrolling) {
      this.handleDisplayHotspots();
    } else {
      deleteHotspots(allHotspotDom);
    }
  }

  // Permet de connaitre l etat du zoom sur l image
  setScrolled(scale: number) {
    this.isScrolling = scale !== 1;
  }

  // reset scrolling de l image
  resetScroll() {
    if (this.isScrolling) {
      this.scrolled.reset();
      this.handleDisplayHotspots();
      this.isScrolling = false;
    }
  }

  // auto rotation
  autoRotate() {
    const divListener = this.renderRoot.querySelector('.player360Listener');
    const events = [
      'onclick',
      'mousedown',
      'onmousedown',
      'mousewheel',
      'DOMMouseScroll',
      'touchstart',
    ];

    const rotate = setInterval(() => {
      const numberTotalImage = this.loadedImagesLowQuality.length;
      this.setNextID(numberTotalImage);
      this.visibleImgId === 0 && clearInterval(rotate);
    }, 200);

    events.forEach((type) => {
      divListener &&
        divListener.addEventListener(type, () => {
          clearInterval(rotate);
          this.touched = true;
        });
    });
  }

  // Chargement des images
  loadingImages(images: IImage[]) {
    return new Promise(async (resolve) => {
      const nbTotalImg = images.length;
      const nbImageToLoad = 4;
      let imgToLoad = [];
      let previousImgLoad: any = [];
      let nbLoading = 0;
      let x = 0;

      while (x < nbTotalImg) {
        x = nbImageToLoad * Math.pow(2, nbLoading);
        imgToLoad = this.findImagesToLoad(previousImgLoad, nbTotalImg, x);
        previousImgLoad = [].concat(previousImgLoad, imgToLoad);
        await this.createImages(imgToLoad);
        nbLoading += 1;
      }

      resolve(true);
    });
  }

  // Recherche les images à charger
  findImagesToLoad(previousImgLoad: string, nbTotalImg: number, x: number) {
    let nextImgToLoad = [];

    for (let i = 0; i < x; i += 1) {
      nextImgToLoad.push(Math.floor((i * nbTotalImg) / x));
    }

    nextImgToLoad = nextImgToLoad.filter((val) =>
      this.doesNotInclude(previousImgLoad, val)
    );

    nextImgToLoad = this.arrayUnique(nextImgToLoad);

    return nextImgToLoad;
  }

  // Créer les images à charger
  createImages(imgToLoad: any) {
    return new Promise((resolve) => {
      const containerToLoad = this.renderRoot.querySelector(
        '.loadedImagesLowQualityContainer'
      ) as HTMLElement;

      imgToLoad.forEach((i: number, index: number) => {
        const image = this.images[i].urlLow;
        const fallbackImage = this.images[i].url;

        this.loadImage(containerToLoad, image, fallbackImage).then(
          (error: boolean) => {
            if (i === 0) {
              const el = this.renderRoot.querySelector(
                '.player360Image'
              ) as HTMLImageElement;
              el.src = this.isFullscreen || error ? this.images[i].url : image;
            }

            if (i === imgToLoad[imgToLoad.length - 1]) {
              this.loading = false;
            }

            const arr = [i, ...this.loadedImagesLowQuality];
            this.loadedImagesLowQuality = this.sortArray(arr);
            this.loadedImagesLowQualityErrors[i] = error;
            index === imgToLoad.length - 1 && resolve(true);
          }
        );
      });
    });
  }

  loadImagesBackground(images: IImage[]) {
    const containerToLoad = this.renderRoot.querySelector(
      '.loadedImagesContainer'
    ) as HTMLElement;

    images.forEach((image: IImage, index: number) => {
      this.loadImage(containerToLoad, image.url).then(() => {
        const arr = [index, ...this.loadedImages];
        this.loadedImages = this.sortArray(arr);
      });
    });
  }

  // Charge une image
  loadImage(
    containerToLoad: HTMLElement,
    urlImage: string,
    fallbackImage?: string
  ): Promise<boolean> {
    return new Promise((resolve) => {
      const image = new Image();
      image.onload = () => {
        containerToLoad.appendChild(image);
        resolve(false);
      };

      image.onerror = () => {
        if (fallbackImage) {
          image.src = fallbackImage;
          resolve(true);
        }
      };

      image.src = urlImage;
    });
  }

  // Change de vue en affichant l'image et les hotspots
  changeViews() {
    if (this.visibleImgId !== -1) {
      this.displayImage();

      this.initializeScroll();

      if (this.disableAutoRotate) {
        this.handleDisplayHotspots();
      }
    }
  }

  initializeScroll() {
    const divEvent = this.renderRoot.querySelector('.player360Listener');
    const image = this.renderRoot.querySelector('.player360Image');

    this.scrolled.init(image, 10, 0.1, divEvent, this.setScrolled.bind(this));
  }

  // Afficher l'image avec son id
  displayImage() {
    const img = this.renderRoot.querySelector(
      '.player360Image'
    ) as HTMLImageElement;
    const url =
      this.isFullscreen || this.loadedImagesLowQualityErrors[this.visibleImgId]
        ? this.images[this.visibleImgId].url
        : this.images[this.visibleImgId].urlLow;

    img.src = url;
    img.onerror = () => {
      img.src = this.images[this.visibleImgId].url;
    };
  }

  // Change l'id de l'image à afficher par rapport au déplacement
  newImageIdOnMove(e: any, data: any) {
    let newImageIndex;

    if (!data) return;
    const container = this.renderRoot.querySelector(
      '.player360Listener'
    ) as HTMLElement;
    const offsetWidthContainer = container && container.offsetWidth;
    const numberTotalImage = this.loadedImagesLowQuality.length;
    const step = offsetWidthContainer / numberTotalImage / 1.2;
    const delta = Math.ceil(e.deltaX / step);

    if (!this.imageIndexOnPan) {
      this.imageIndexOnPan = this.loadedImagesLowQuality.indexOf(
        this.visibleImgId
      );
    }

    newImageIndex =
      ((Math.round(this.imageIndexOnPan + delta + numberTotalImage) %
        numberTotalImage) +
        numberTotalImage) %
      numberTotalImage;

    this.visibleImgId = this.loadedImagesLowQuality[newImageIndex];
  }

  // set le prochain id selon la position dans le tableau
  setNextID(numberTotalImage: number) {
    const indexOfVisibleImages = this.loadedImagesLowQuality.indexOf(
      this.visibleImgId
    );

    if (indexOfVisibleImages + 1 === numberTotalImage) {
      this.visibleImgId = this.loadedImagesLowQuality[0];
    } else {
      this.visibleImgId = this.loadedImagesLowQuality[indexOfVisibleImages + 1];
    }
  }

  // Range un tableau dans l'ordre
  sortArray(arr: number[]) {
    return arr.sort((a: number, b: number) => {
      return a - b;
    });
  }

  // Retourne un tableau sans doublon
  arrayUnique(arr: any) {
    return arr.filter((item: any, index: number) => arr.indexOf(item) >= index);
  }

  // Verifie qu'une valeur n'existe pas dans le tableau
  doesNotInclude(container: any, value: any) {
    let returnValue = false;
    const pos = container.indexOf(value);
    if (pos < 0) {
      returnValue = true;
    }
    return returnValue;
  }
}

window.customElements.define('player-360-outside', Player360Outside);

import { IImage, ImageType, Image } from './image';
import {InspectRepairOrder} from "./inspectionRepairOrder";

interface IShootingPhotos {
  images: IImage[];
}

class ShootingPhotos implements IShootingPhotos {
  images: IImage[] = [];

  static buildStandardIfExist(standard: any, inspection: any, inspectRepairOrder: any) {
    if (standard) return new ShootingPhotos().build(standard);
    if (inspection) return new ShootingPhotos().build(inspection);
    if (inspectRepairOrder) {
      return new InspectRepairOrder().build(inspectRepairOrder);
    }

    return new ShootingPhotos;
  }

  build(shooting: any): IShootingPhotos {
    if (!shooting) return {} as IShootingPhotos;

    try {
      const originals = shooting.originals.map(
          (el:any, index: number) =>
              ShootingPhotos.buildImage(el, index, ImageType.original, shooting),
      );

      const stampeds = shooting.stampeds.map(
          (el:any, index: number) =>
              ShootingPhotos.buildImage(el, index, ImageType.stamped, shooting),
      );

      return {
        images: [...originals, ...stampeds],
      } as IShootingPhotos;
    } catch (e) {
      return new ShootingPhotos();
    }
  }

  private static buildImage(el: any, index: number, imageType: ImageType, standard: any) {
    return new Image(
        imageType,
        el.url,
        el.order,
        standard['originals'][index].hotspots,
    );
  }
}

export { IShootingPhotos, ShootingPhotos };

import {LitElement, html} from 'lit';
import {property} from 'lit/decorators.js';

import '../playerButtons/buttons/button-fullscreen';
import './buttons/button-view';
import {styles} from './buttons/styles';
import {IShootings, IVideo} from '../../models';
import {translate} from 'lit-translate';

export class PlayerButtons extends LitElement {
  static styles = [styles];

  @property() shootings: IShootings = {
    panoramaOutsideClose: {images: []},
    panoramaOutsideOpen: {images: []},
    standard: {images: []},
    panoramaInside: {url: '', hotspots: []},
    video: {
      name: '',
      url: '',
      partner: '',
      reference: '',
      markers: [],
    },
  };
  @property() viewToDisplay: string = '';
  @property() addLabelOnButtons: boolean = false;
  @property() isFullScreen: boolean = false;
  @property() shootingGallery: any[] = [];
  @property() shootingPanoramaOutsideCloseImages: any[] = [];
  @property() shootingPanoramaOutsideOpenImages: any[] = [];
  @property() shootingPanoramaInsideImage: string = '';
  @property() shootingVideo: any = null;
  @property() galleryLength: number = 0;
  @property() displayButtons: boolean = true;
  @property() displayVideoButton: boolean = false;
  @property() display360OutsideCloseButton: boolean = false;
  @property() display360OutsideOpenButton: boolean = false;
  @property() displayInsideButton: boolean = false;
  @property() buttonsColor: string[] = [];
  @property() buttonsCheckedColor: string[] = [];
  @property() buttonsHoverColor: string[] = [];
  @property() buttonIconWidth: string = '';
  @property() buttonIconHeight: string = '';
  @property() buttonExternalClose: string = '';
  @property() buttonExternalOpen: string = '';
  @property() buttonInternal: string = '';
  @property() buttonVideo: string = '';
  @property() numberOfActiveButton: number = 0;
  @property() displayPlayerBottom: boolean = false;

  render() {
    return html`
      <div class="stampyt-buttons">
        <!-- Button fullscreen -->
        <button-fullscreen
          .isFullScreen="${this.isFullScreen}"
          .shootingGallery="${this.shootingGallery}"
          .galleryLength="${this.galleryLength}"
        ></button-fullscreen>

        <!-- Button 360 porte fermer -->
        ${this.displayButtons && this.display360OutsideCloseButton
          ? html` <button-view
              id="button360Closed"
              name="PANORAMA_OUTSIDE_CLOSE"
              .label="${translate('panoramaOutsideCloseLabel')}"
              viewToDisplay="${this.viewToDisplay}"
              .addLabelOnButtons="${this.addLabelOnButtons}"
              buttonsColor="${this.buttonsColor}"
              buttonsCheckedColor="${this.buttonsCheckedColor}"
              buttonsHoverColor="${this.buttonsHoverColor}"
              buttonIconWidth="${this.buttonIconWidth}"
              buttonIconHeight="${this.buttonIconHeight}"
              buttonIcon="${this.buttonExternalClose}"
              @child-property-changed="${this.handleTypeOfViewChange}"
            >
            </button-view>`
          : html``}
        ${this.displayButtons && this.display360OutsideOpenButton
          ? html` <button-view
              id="button360Open"
              name="PANORAMA_OUTSIDE_OPEN"
              .label="${translate('panoramaOutsideOpenLabel')}"
              viewToDisplay="${this.viewToDisplay}"
              .addLabelOnButtons="${this.addLabelOnButtons}"
              buttonsColor="${this.buttonsColor}"
              buttonsCheckedColor="${this.buttonsCheckedColor}"
              buttonsHoverColor="${this.buttonsHoverColor}"
              buttonIconWidth="${this.buttonIconWidth}"
              buttonIconHeight="${this.buttonIconHeight}"
              buttonIcon="${this.buttonExternalOpen}"
              @child-property-changed="${this.handleTypeOfViewChange}"
            >
            </button-view>`
          : html``}
        ${this.displayButtons && this.displayInsideButton
          ? html` <button-view
              id="button360In"
              name="PANORAMA_INSIDE"
              .label="${translate('panoramaInsideLabel')}"
              viewToDisplay="${this.viewToDisplay}"
              .addLabelOnButtons="${this.addLabelOnButtons}"
              buttonsColor="${this.buttonsColor}"
              buttonsCheckedColor="${this.buttonsCheckedColor}"
              buttonsHoverColor="${this.buttonsHoverColor}"
              buttonIconWidth="${this.buttonIconWidth}"
              buttonIconHeight="${this.buttonIconHeight}"
              buttonIcon="${this.buttonInternal}"
              @child-property-changed="${this.handleTypeOfViewChange}"
            >
            </button-view>`
          : html``}
        ${this.displayButtons && this.displayVideoButton
          ? html` <button-view
              id="buttonVideoStampyt"
              name="VIDEO"
              .label="${translate('videoLabel')}"
              viewToDisplay="${this.viewToDisplay}"
              .addLabelOnButtons="${this.addLabelOnButtons}"
              buttonsColor="${this.buttonsColor}"
              buttonsCheckedColor="${this.buttonsCheckedColor}"
              buttonsHoverColor="${this.buttonsHoverColor}"
              buttonIconWidth="${this.buttonIconWidth}"
              buttonIconHeight="${this.buttonIconHeight}"
              buttonIcon="${this.buttonVideo}"
              @child-property-changed="${this.handleTypeOfViewChange}"
            >
            </button-view>`
          : html``}
      </div>
    `;
  }

  connectedCallback() {
    super.connectedCallback();

    this.shootingPanoramaOutsideCloseImages =
      this.shootings.panoramaOutsideClose.images;
    this.shootingPanoramaOutsideOpenImages =
      this.shootings.panoramaOutsideOpen.images;
    this.shootingPanoramaInsideImage = this.shootings.panoramaInside.url;
    this.shootingVideo = this.shootings.video;

    this.display360OutsideCloseButtonHandler(
      this.shootingPanoramaOutsideCloseImages
    );
    this.display360OutsideOpenButtonHandler(
      this.shootingPanoramaOutsideOpenImages
    );
    this.displayInsideButtonHandler(this.shootingPanoramaInsideImage);
    this.displayVideoButtonHandler(this.shootingVideo);
    this.disabledButtons();
  }

  protected firstUpdated(changedProperties: any) {
    super.firstUpdated(changedProperties);

    this.watchForHover();
  }

  handleTypeOfViewChange(e: any) {
    this.dispatchEvent(
      new CustomEvent('child-property-changed', {
        bubbles: true, // propagates beyond self
        composed: true, // propagates through shadow boundaries
        detail: {
          childProperty: e.detail.childProperty,
        },
      })
    );
  }

  displayVideoButtonHandler(newValue: IVideo) {
    const currentNumberOfActiveButton = this.numberOfActiveButton;
    const hasReference = newValue.reference !== '';
    const isStampytPartner = newValue.partner === 'STAMPYT';

    if (hasReference || isStampytPartner) {
      this.displayVideoButton = true;
      this.numberOfActiveButton = currentNumberOfActiveButton + 1;
    }
  }

  display360OutsideCloseButtonHandler(newValue: any[]) {
    this.countButton('display360OutsideCloseButton', newValue);
  }

  display360OutsideOpenButtonHandler(newValue: any[]) {
    this.countButton('display360OutsideOpenButton', newValue);
  }

  displayInsideButtonHandler(newValue: string) {
    this.countButton('displayInsideButton', newValue);
  }

  disabledButtons() {
    this.displayButtons = !(
      this.numberOfActiveButton === 1 && !this.displayPlayerBottom
    );
  }

  countButton(name: string, newValue: any) {
    const currentNumberOfActiveButton = this.numberOfActiveButton;

    if (newValue.length > 0) {
      // @ts-ignore
      this[name] = true;
      this.numberOfActiveButton = currentNumberOfActiveButton + 1;
    }
  }

  // Ecoutent les actions de l'utilisateur pour activer ou dÃ©sactiver
  // le hover sur les boutons quand on utilise le tactile
  watchForHover() {
    const container = this.renderRoot.querySelector(
      '.stampyt-buttons'
    ) as HTMLElement;
    let hasHoverClass = false;
    let lastTouchTime: number = 0;

    function enableHover() {
      // filter emulated events coming from touch events
      if (new Date().getTime() - lastTouchTime < 500) return;
      if (hasHoverClass) return;

      container.className += ' hasHover';
      hasHoverClass = true;
    }

    function disableHover() {
      if (!hasHoverClass) return;

      container.className = container.className.replace(' hasHover', '');
      hasHoverClass = false;
    }

    function updateLastTouchTime() {
      lastTouchTime = new Date().getTime();
    }

    document.addEventListener('touchstart', updateLastTouchTime, true);
    document.addEventListener('touchstart', disableHover, true);
    document.addEventListener('mousemove', enableHover, true);

    enableHover();
  }
}

window.customElements.define('player-buttons', PlayerButtons);

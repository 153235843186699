import {LitElement, html} from 'lit';
import {property} from 'lit/decorators.js';
import {styles} from './styles';
import {
  buttonsColorStyle,
  setButtonDimension,
  changeImageButton,
} from '../../../utils/buttonsUtilities';

export class ButtonView extends LitElement {
  static styles = [styles];

  @property() id: string = '';
  @property() name: string = '';
  @property() label: string = '';
  @property() addLabelOnButtons: boolean = false;
  @property() buttonsColor: string = '';
  @property() buttonsCheckedColor: string = '';
  @property() buttonsHoverColor: string = '';
  @property() viewToDisplay: string = '';
  @property() buttonIconWidth: string = '';
  @property() buttonIconHeight: string = '';
  @property() buttonIcon: string = '';

  protected updated(changedProperties: any) {
    super.updated(changedProperties);

    if (changedProperties.has('viewToDisplay')) {
      this.changeCheck(this.viewToDisplay);
    }
  }

  render() {
    return html` <div id=${this.id} class="stampyt-button-container">
      ${this.renderLabel()}

      <div
        class="stampyt-button360 stampyt-button-color"
        @click="${this.changeView}"
      >
        ${this.svgToRender()}
      </div>
    </div>`;
  }

  renderLabel() {
    if (this.addLabelOnButtons) {
      return html`<label class="button-360-label">${this.label}</label>`;
    }

    return html``;
  }

  svgToRender() {
    if (this.name === 'VIDEO') {
      return html`
        <svg
          style="transform: scale(0.70);"
          fill="#ffffff"
          height="45"
          viewBox="0 96 960 960"
          width="45"
        >
          <path
            d="M140 896q-24 0-42-18t-18-42V316q0-24 18-42t42-18h520q24 0 42 18t18 42v215l160-160v410L720 621v215q0 24-18 42t-42 18H140Zm0-60h520V316H140v520Zm0 0V316v520Z"
          />
        </svg>
      `;
    }

    if (this.name === 'PANORAMA_INSIDE') {
      return html`
        <svg
          fill="#ffffff"
          id="Calque_1"
          data-name="Calque 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 56 56"
        >
          <path
            class="st4"
            d="M20.2,20c-1.2-0.9-1.5-2.6-0.7-3.8c0.9-1.2,2.6-1.5,3.8-0.7c1.2,0.9,1.5,2.6,0.7,3.8C23.2,20.5,21.5,20.8,20.2,20 M34.8,38.3h-9.7c-2,0-3.7-1.5-4.1-3.5l-2.7-12.9h-2.7l2.7,13.4c0.5,3.3,3.4,5.8,6.8,5.8h9.7M35.1,32.8h-6.7L27,27.2c2.2,1.2,4.5,2.1,7,1.7v-2.9c-2.2,0.4-4.7-0.4-6.4-1.7l-2.2-1.7c-0.3-0.2-0.7-0.4-1-0.5c-0.4-0.1-0.9-0.2-1.4-0.1h0c-1.7,0.3-2.8,1.9-2.5,3.6l1.8,8.1c0.4,2,2.1,3.3,4,3.3h9.4l5.2,4.1l2.1-2.1"
          />
        </svg>
      `;
    }

    if (this.name === 'PANORAMA_OUTSIDE_CLOSE') {
      return html`
        <svg
          fill=" #ffffff"
          id="Calque_1"
          data-name="Calque 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 56 56"
        >
          <path
            class="st3"
            d="M50,34c0-1.1-1.1-2.1-3-3v1.1c1.3,0.7,2,1.3,2,1.9c0,1.8-6.1,4.2-16,4.8v1C42.7,39.2,50,36.8,50,34z"
          />
          <path
            class="st3"
            d="M29.9,39.8c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0l0,0c-0.1-0.1-0.1-0.2-0.3-0.3l0,0L29.6,39l-2.5-2.5l-0.7,0.7l1.8,1.8c-0.1,0-0.1,0-0.2,0c-12.8,0-21-3-21-5c0-0.6,0.7-1.3,2-1.9V31c-1.9,0.9-3,1.9-3,3c0,3.3,9.8,6,22,6c0.1,0,0.2,0,0.2,0l-1.8,1.8l0.7,0.7l2.6-2.6l0,0l0,0C29.8,39.9,29.8,39.9,29.9,39.8C29.9,39.8,29.9,39.8,29.9,39.8z"
          />
          <path
            class="st3"
            d="M46,28.3L45.7,28c0.2-2.2-0.4-3.4-2.5-4.2c-1.4-0.5-3.1-0.6-4.4-1c-0.4-0.1-0.7-0.2-1-0.3c-1.7-0.6-3.8-2.4-5.6-3.2c-2.1-1-3.8-1.1-6-1.2c-1.1,0-4.9-0.3-8-0.2c-1.5,0.1-3.5,0-4.5,1.4l-2.8,3.9c-0.6,1-0.6,3.2-0.6,4.1c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.1,0.3c0,1.4,0.4,2.4,1.2,3.5c0.2,0.3,0.6,0.6,1,0.6l1,0.2c0.7,0.8,1.7,1.4,2.9,1.4c1.2,0,2.2-0.6,2.9-1.4h18.3c0.7,0.8,1.7,1.4,2.9,1.4c1.2,0,2.3-0.6,3-1.5l1.6-0.2h0C46,31.9,46.3,28.9,46,28.3z M16,32.6c-1.5,0-2.6-1.2-2.6-2.6c0-1.5,1.2-2.6,2.6-2.6c1.4,0,2.6,1.2,2.6,2.6C18.6,31.4,17.4,32.6,16,32.6z M16.2,22.7c-0.3,0-0.5-0.2-0.6-0.5c-0.1-0.3-0.2-0.5-0.1-0.8c0.2-0.7,0.6-1.4,1.2-1.9c0.4-0.3,0.9-0.4,1.5-0.4c1.9,0,3.8,0,5.8,0.1l0.6,3.8L16.2,22.7z M33.9,23.4l-8.3-0.3L25,19.3c1,0,2,0.1,3,0.2c0.9,0.1,1.7,0.3,2.4,0.5c1.3,0.4,2.2,1,3.7,1.9h0C34.8,22.3,35,23.5,33.9,23.4z M40.1,32.6c-1.5,0-2.6-1.2-2.6-2.6c0-1.5,1.2-2.6,2.6-2.6c1.4,0,2.6,1.2,2.6,2.6C42.8,31.4,41.6,32.6,40.1,32.6z"
          />
        </svg>
      `;
    }

    if (this.name === 'PANORAMA_OUTSIDE_OPEN') {
      return html`
        <svg
          fill="#ffffff"
          id="Calque_1"
          data-name="Calque 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 56 56"
        >
          <style type="text/css">
            .st4Open {
              fill: none;
            }
          </style>
          <path
            class="st3"
            d="M50,34c0-1.1-1.1-2.1-3-3v1.1c1.3,0.7,2,1.3,2,1.9c0,1.8-6.1,4.2-16,4.8v1C42.7,39.2,50,36.8,50,34z"
          />
          <path
            class="st3"
            d="M29.9,39.8c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0l0,0c-0.1-0.1-0.1-0.2-0.3-0.3l0,0L29.6,39l-2.5-2.5l-0.7,0.7l1.8,1.8c-0.1,0-0.1,0-0.2,0c-12.8,0-21-3-21-5c0-0.6,0.7-1.3,2-1.9V31c-1.9,0.9-3,1.9-3,3c0,3.3,9.8,6,22,6c0.1,0,0.2,0,0.2,0l-1.8,1.8l0.7,0.7l2.6-2.6l0,0l0,0C29.8,39.9,29.8,39.9,29.9,39.8C29.9,39.8,29.9,39.8,29.9,39.8z"
          />
          <path
            class="st4Open"
            d="M20,30c0,0.3-0.1,0.7-0.1,1h6L24,19.2c-1.9-0.1-3.8-0.1-5.8-0.1c-0.6,0-1.1,0.1-1.5,0.4c-0.6,0.4-2.2,3.5-2.4,4.1c-0.1,0.3,0.6,2,0.7,2.3c0,0,0,0.1,0.1,0.1C15.4,26,15.7,26,16,26C18.2,26,20,27.8,20,30z"
          />
          <path
            class="st4Open"
            d="M36.1,31c-0.1-0.3-0.1-0.7-0.1-1c0-1.6,1-3,2.3-3.6c0-0.6-0.2-1.4-1-2L34,21.8c-1.4-0.9-2.4-1.5-3.7-1.9c-0.7-0.2-1.5-0.4-2.4-0.5c-1-0.1-2-0.1-3-0.2L26.8,31H36.1z"
          />
          <path
            class="st3"
            d="M46,28.3L45.7,28c0.2-2.2-0.4-3.4-2.5-4.2c-1.4-0.5-3.1-0.6-4.4-1c-0.4-0.1-0.7-0.2-1-0.3c-1.7-0.6-3.8-2.4-5.6-3.2c-2.1-1-3.8-1.1-6-1.2c-1.1,0-4.9-0.3-8-0.2c-0.9,0-2.1,0.1-3,0.4l-4.1-4.1L10.7,14l-0.5-0.2L7,13l-0.3,1l3.5,0.9l4,4c-0.2,0.2-0.4,0.3-0.6,0.6l-2.8,3.9c-0.6,1-0.6,3.2-0.6,4.1c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.1,0.3c0,1.4,0.4,2.4,1.2,3.5c0.2,0.3,0.6,0.6,1,0.6l0.7,0.1l0.3,0.1c0.7,0.8,1.7,1.4,2.9,1.4c1.2,0,2.2-0.6,2.9-1.4h0.3h17.6h0.4c0.7,0.8,1.7,1.4,2.9,1.4c1.2,0,2.3-0.6,3-1.5l0.1,0l1.5-0.2h0C46,31.9,46.3,28.9,46,28.3z M18.4,31C18,31.9,17,32.6,16,32.6c-1.5,0-2.6-1.2-2.6-2.6c0-1.4,1-2.5,2.4-2.6c0.1,0,0.2,0,0.3,0c1.4,0,2.6,1.2,2.6,2.6C18.6,30.3,18.5,30.7,18.4,31z M19.9,31c0.1-0.3,0.1-0.7,0.1-1c0-2.2-1.8-4-4-4c-0.3,0-0.6,0-0.9,0.1C15,26.1,15,26,15,26c-0.1-0.3-0.8-2-0.7-2.3c0.2-0.7,1.8-3.7,2.4-4.1c0.4-0.3,0.9-0.4,1.5-0.4c1.9,0,3.8,0,5.8,0.1L25.8,31H19.9z M36,30c0,0.3,0.1,0.7,0.1,1h-9.3L25,19.3c1,0,2,0.1,3,0.2c0.9,0.1,1.7,0.3,2.4,0.5c1.3,0.4,2.2,1,3.7,1.9l3.3,2.6c0.7,0.6,1,1.3,1,2C37,27,36,28.4,36,30z M40.1,32.6c-1.5,0-2.6-1.2-2.6-2.6c0-1.5,1.2-2.6,2.6-2.6c1.4,0,2.6,1.2,2.6,2.6C42.8,31.4,41.6,32.6,40.1,32.6z"
          />
        </svg>
      `;
    }

    return html``;
  }

  firstUpdated(changedProperties: any) {
    super.firstUpdated(changedProperties);
    const btnColorList = [
      this.buttonsColor,
      this.buttonsCheckedColor,
      this.buttonsHoverColor,
    ];
    const btnBlock = this.renderRoot.querySelector(
      `#${this.id}`
    ) as HTMLElement;
    const btn = btnBlock.querySelector('.stampyt-button360') as HTMLElement;

    const container = this.renderRoot as HTMLElement;

    buttonsColorStyle(btnColorList, btn, container);
    changeImageButton(btnBlock, this.buttonIcon);
    setButtonDimension(this.buttonIconWidth, this.buttonIconHeight, btn);
  }

  changeCheck(n: string) {
    const btn = this.renderRoot.querySelector(
      '.stampyt-button360'
    ) as HTMLElement;

    if (n === this.name) {
      this.addClass(btn, 'checked');
    } else {
      this.removeClass(btn, 'checked');
    }
  }

  changeView() {
    this.customDispatchEvent(this.name);
  }

  addClass(el: HTMLElement, className: string) {
    el.classList.add(className);
  }

  removeClass(el: HTMLElement, className: string) {
    el.classList.remove(className);
  }

  customDispatchEvent(name: string) {
    this.dispatchEvent(
      new CustomEvent('child-property-changed', {
        bubbles: true, // propagates beyond self
        composed: true, // propagates through shadow boundaries
        detail: {
          childProperty: name,
        },
      })
    );
  }
}

window.customElements.define('button-view', ButtonView);

import {LitElement, html} from 'lit';
import {styles} from './styles';
import {defineNextImageId} from '../../utils/utils';
import {IImage} from '../../models';

import {convertUrlSelectedImage} from '../../utils/cloudflare';
import {prefetchImages} from '../../utils/usePreloadImages';

import 'hammerjs';
import {customElement, property} from 'lit/decorators.js';

@customElement('player-bottom')
export class PlayerBottom extends LitElement {
  static styles = [styles];

  @property() shootingGallery: IImage[] = [];
  @property() limitedShootingGallery: IImage[] = [];
  @property() displayedShootingGallery: IImage[] = [];
  @property() idSelectedImage: number = -1;
  @property() viewToDisplay: string = '';
  @property() galleryLength: number = 0;
  @property() eventChangeImages: any;
  @property() nbGalleryImageToLoad: number = 10;
  @property() isClicked: Boolean = false;

  async connectedCallback() {
    super.connectedCallback();

    this.limitedShootingGallery = this.shootingGallery.slice(
      0,
      this.nbGalleryImageToLoad
    );
    this.displayedShootingGallery = this.limitedShootingGallery;
    this.galleryLength = this.shootingGallery.length;
    this.eventChangeImages = this.changeImageHandler.bind(this);
  }

  firstUpdated(changedProperties: any) {
    super.firstUpdated(changedProperties);

    const playerBottom = this.renderRoot.querySelector('.playerBottom');
    const limitedShootingGalleryUrls = this.getUrlsFromShootingGallery(
      this.limitedShootingGallery
    );

    this.initHammer(playerBottom);
    prefetchImages(limitedShootingGalleryUrls);
    this.initImageResize();
  }

  updated(changedProperties: any) {
    super.update(changedProperties);

    if (changedProperties.has('viewToDisplay')) {
      if (this.viewToDisplay !== 'GALLERY') {
        document.removeEventListener('keydown', this.eventChangeImages);
      } else {
        if (this.isClicked) {
          document.addEventListener('keydown', this.eventChangeImages);
        }
      }
    }

    if (changedProperties.has('idSelectedImage')) {
      if (changedProperties.get('idSelectedImage') === undefined) return;
      this.moveImages(this.idSelectedImage);
    }
  }

  render() {
    return html`
      <div class="playerBottom">
        <div class="stampyt-gallery">
          ${this.displayedShootingGallery.map(
            (item, index) => html`
              <div class="imageContainer">
                <div
                  class=${this.isBright(
                    this.idSelectedImage,
                    index,
                    this.viewToDisplay
                  )}
                ></div>
                <img
                  id="${index}"
                  class="image nodrag"
                  @click="${this.changeImageHandler}"
                  src="${convertUrlSelectedImage(item.url)}"
                  onerror='this.onerror = null; this.src="${item.url}"'
                />
              </div>
            `
          )}
        </div>
        <svg
          data-params="prev"
          @click="${this.changeImageHandler}"
          class="leftBottomThumbnail"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 444.531 444.531"
        >
          <path
            d="M213.13 222.409L351.88 83.653c7.05-7.043 10.567-15.657 10.567-25.841 0-10.183-3.518-18.793-10.567-25.835l-21.409-21.416C323.432 3.521 314.817 0 304.637 0s-18.791 3.521-25.841 10.561L92.649 196.425c-7.044 7.043-10.566 15.656-10.566 25.841s3.521 18.791 10.566 25.837l186.146 185.864c7.05 7.043 15.66 10.564 25.841 10.564s18.795-3.521 25.834-10.564l21.409-21.412c7.05-7.039 10.567-15.604 10.567-25.697 0-10.085-3.518-18.746-10.567-25.978L213.13 222.409z"
            fill="#FFF"
          />
        </svg>
        <svg
          data-params="next"
          @click="${this.changeImageHandler}"
          class="rightBottomThumbnail"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 444.819 444.819"
        >
          <path
            d="M352.025 196.712L165.884 10.848C159.029 3.615 150.469 0 140.187 0s-18.842 3.619-25.697 10.848L92.792 32.264c-7.044 7.043-10.566 15.604-10.566 25.692 0 9.897 3.521 18.56 10.566 25.981L231.545 222.41 92.786 361.168c-7.042 7.043-10.564 15.604-10.564 25.693 0 9.896 3.521 18.562 10.564 25.98l21.7 21.413c7.043 7.043 15.612 10.564 25.697 10.564 10.089 0 18.656-3.521 25.697-10.564L352.025 248.39c7.046-7.423 10.571-16.084 10.571-25.981.001-10.088-3.525-18.654-10.571-25.697z"
            fill="#FFF"
          />
        </svg>
      </div>
    `;
  }

  getUrlsFromShootingGallery(shooting: IImage[]) {
    return shooting.map((s) => s.url);
  }

  initHammer(container: Element | null) {
    if (!container) return;

    // @ts-ignore
    const hammer = new Hammer(container);

    // @ts-ignore
    hammer.get('pan').set({direction: Hammer.DIRECTION_ALL});
  }

  setHeightImageGallery() {
    function adjustImg(
      allImg: NodeListOf<HTMLElement>,
      playerBottom: HTMLElement
    ) {
      allImg.forEach((el: HTMLElement) => {
        if (playerBottom.clientHeight === 0) {
          el.style.height = '100%';
        } else {
          el.style.height = `${playerBottom.clientHeight}px`;
        }

        el.style.width = 'auto';
      });
    }

    // définis la hauteur des images (principalement pour IE)
    if (this.shootingGallery.length > 0) {
      const allImgContainer = this.renderRoot.querySelectorAll(
        '.image'
      ) as NodeListOf<HTMLElement>;
      const playerBottom = this.renderRoot.querySelector(
        '.playerBottom'
      ) as HTMLElement;

      adjustImg(allImgContainer, playerBottom);
    }
  }

  initImageResize() {
    this.setHeightImageGallery();
    window.addEventListener('resize', this.setHeightImageGallery.bind(this));
  }

  changeImageHandler(
    e: KeyboardEvent | PointerEvent | MouseEvent | TouchEvent
  ) {
    const elParams: string | null = this.getAttributeFromEvent(e);
    this.setViewToDisplay('GALLERY');

    this.firstEventOnly();

    const newImageId: number = this.defineImageIdWithEventType(e, elParams);
    if (newImageId === -1) return;

    this.customEventChangeView(newImageId);
    this.setSelectedImageId(newImageId);
  }

  firstEventOnly() {
    if (!this.isClicked) {
      this.loadMoreImages();
    }
    this.isClicked = true;
    document.addEventListener('keydown', this.eventChangeImages);
  }

  defineImageIdWithEventType(
    e: KeyboardEvent | PointerEvent | MouseEvent | TouchEvent,
    elParams: string | null
  ): number {
    if (e.type === 'click') {
      const target = e.target as HTMLElement;
      return !elParams
        ? parseInt(target && target.id, 10)
        : defineNextImageId(this.idSelectedImage, elParams, this.galleryLength);
    }

    if (e.type === 'keydown') {
      return defineNextImageId(
        this.idSelectedImage,
        elParams,
        this.galleryLength
      );
    }

    return -1;
  }

  getAttributeFromEvent(
    event: KeyboardEvent | PointerEvent | MouseEvent | TouchEvent
  ): string | null {
    const target = event.currentTarget as HTMLElement;

    if (event instanceof KeyboardEvent) {
      return event.key;
    }

    if (
      event instanceof PointerEvent ||
      event instanceof MouseEvent ||
      event instanceof TouchEvent
    ) {
      return target.getAttribute('data-params' || target.dataset.params);
    }

    return null;
  }

  setSelectedImageId(id: number): void {
    this.idSelectedImage = id;
  }

  setViewToDisplay(type: 'GALLERY'): void {
    this.viewToDisplay = type;
  }

  // Place l'image selectionne au milieu de la galerie
  placeImgMiddleGallery(container: HTMLElement): void {
    const galleryContainer = this.renderRoot.querySelector(
      '.stampyt-gallery '
    ) as HTMLElement;
    const middleGalleryWidth = galleryContainer.offsetWidth / 2;
    const imgPos = container.offsetLeft + container.offsetWidth / 2;
    const scrollLeft = galleryContainer.scrollLeft;
    const total = middleGalleryWidth + scrollLeft;

    if (total < imgPos) {
      galleryContainer.scrollLeft += imgPos - total;
    } else {
      galleryContainer.scrollLeft -= total - imgPos;
    }
  }

  loadMoreImages(): void {
    if (this.displayedShootingGallery.length === this.shootingGallery.length)
      return;

    this.displayedShootingGallery = this.shootingGallery;
    const displayedShootingGalleryUrls = this.displayedShootingGallery
      .slice(this.nbGalleryImageToLoad, this.displayedShootingGallery.length)
      .map((e) => e.url);
    prefetchImages(displayedShootingGalleryUrls);
  }

  moveImages(newId: number): void {
    if (newId === null) return;

    const nodeList = this.renderRoot.querySelectorAll('.image');
    const currentNodeListContainer = nodeList.item(newId)
      .parentNode as HTMLElement;

    if (!currentNodeListContainer) return;

    this.placeImgMiddleGallery(currentNodeListContainer);
  }

  isBright(
    idSelectedImage: number,
    index: number,
    viewToDisplay: string
  ): string {
    return viewToDisplay === 'GALLERY' && idSelectedImage === index
      ? ''
      : 'bright';
  }

  customEventChangeView(id: number): void {
    this.dispatchEvent(
      new CustomEvent('child-property-changed', {
        bubbles: true, // propagates beyond self
        composed: true, // propagates through shadow boundaries
        detail: {
          childProperty: id,
        },
      })
    );
  }
}

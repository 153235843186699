import { css } from 'lit';

export default css`
    .stampytStamp {
        position: absolute;
        z-index: 15;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        pointer-events: none;
        font-family: 'Fira Sans', sans-serif;
    }

    .player360Container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        margin: 0;
        box-sizing: border-box;
        overflow: hidden;
      }

    .player360Listener {
        position: absolute;
        z-index: 15;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        cursor: ew-resize;
        transform: translate3d(0, 0, 0);
      }

      .player360Image {
        width: 100%;
      }

      .loadedImagesContainer,
      .loadedImagesLowQualityContainer,
      .loadedHDImagesContainer {
        display: none;
      }

      .loadedImagesContainer > img,
      .loadedImagesLowQualityContainer > img,
      .loadedHDImagesContainer > img {
        position: absolute;
      }

      .notSelectable {
        user-select: none;
        -moz-user-select: none;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -ms-user-select: none;
      }
`;
